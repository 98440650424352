import * as React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import { useIntl } from "gatsby-plugin-intl";
import styled from "styled-components";
import { Reset } from "styled-reset";
import { ThemeProvider } from "styled-components";

import { ENGLISH_HOMEPAGE, FRENCH_HOMEPAGE } from ".";
import { getPageTitle } from "../Locales";
import { theme } from "../theme/theme";
import FontFaces from "../theme/fonts/font-faces";
import GlobalStyle from "../theme/global";
import { Header } from "../components/header";
import { Footer } from "../components/footer";
import { HalfCenteredRow } from "../components/common/layout";
import { formatBodyRaw } from "../utils/format-body-raw";
import { ContentfulPage } from "../utils/contentful";

const LegalsPage = ({ data }) => {
  const legalsPage: ContentfulPage = formatLegalsQueryResult(data);
  const intl = useIntl();
  return (
    <ThemeProvider theme={theme}>
      <Reset />
      <GlobalStyle />
      <main>
        <Helmet>
          <title>{getPageTitle(legalsPage.title)}</title>
          <style type="text/css">{FontFaces}</style>
          <link
            rel="alternate"
            hrefLang="en-gb"
            href={ENGLISH_HOMEPAGE + "/legals"}
          />
          <link
            rel="alternate"
            hrefLang="en-us"
            href={ENGLISH_HOMEPAGE + "/legals"}
          />
          <link
            rel="alternate"
            hrefLang="en"
            href={ENGLISH_HOMEPAGE + "/legals"}
          />
          <link
            rel="alternate"
            hrefLang="fr"
            href={FRENCH_HOMEPAGE + "/legals"}
          />
          <link
            rel="alternate"
            hrefLang="x-default"
            href={ENGLISH_HOMEPAGE + "/legals"}
          />
          <meta
            name="description"
            content={
              intl.locale === "en"
                ? "What if all your purchases became cheaper, without any effort? Coupons, price tracking, cash back"
                : "Boostez votre pouvoir d'achat ! Et si tout devenait moins cher, sans aucun effort ? Codes promo, suivi des prix, cashback, paiement en plusieurs fois"
            }
            key="description"
          />
        </Helmet>
        <Header />
        <Wrapper>
          <HalfCenteredRow>
            <Heading className="heading">{legalsPage.title}</Heading>
            {formatBodyRaw(legalsPage.body.raw)}
          </HalfCenteredRow>
        </Wrapper>
        <Footer />
      </main>
    </ThemeProvider>
  );
};

export default LegalsPage;

export const legalsQuery = graphql`
  query LegalsQuery($language: String) {
    allContentfulPage(
      filter: {
        contentful_id: { in: ["VAB0ANEffiNTbGa1YRddu"] }
        node_locale: { eq: $language }
      }
    ) {
      nodes {
        node_locale
        title
        body {
          raw
        }
      }
    }
  }
`;

interface LegalsQueryResponse {
  allContentfulPage: {
    nodes: ContentfulPage[];
  };
}

function formatLegalsQueryResult(
  queryResult: LegalsQueryResponse
): ContentfulPage {
  return queryResult.allContentfulPage.nodes[0];
}

const Wrapper = styled.section`
  min-height: 100vh;
  box-sizing: border-box;
  padding: 6.25em 0;
`;

const Heading = styled.h1`
  text-align: center;
  margin-bottom: 1em;
`;
